<template>
   <div class="app-main__inner">
        <div class="text-2xl font-semibold">{{ $t('webshop') }}</div>

        <div class="absolute bg-red-30 inset-0 z-10" @click="hideDropdown" v-if="showProductDropdown || showSupplierDropdown || showWebshopDropdown"></div>
        <div class="container dm-sans-font">
            <div class="row">
                <div class="w-100">
                    <div class="main-card mb-3 card">
                        <div class="card-body">
                            <div class="dropdown-margin-adjust flex flex-col md:flex-row justify-end align-items-center space-x-4">
                                <div class="dd-container">
                                    <span @click="showProductDropdown = !showProductDropdown" class="flex cursor-pointer space-x-4 align-items-center text-gray-900 font-semibold">
                                        {{ $t('productManager') }}
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </span>
                                    <div class="sub-dropdown" v-if="showProductDropdown">
                                        <div>
                                            <ul>
                                                <li>
                                                    <router-link class="no-underline no-deco text-color-theme whitespace-nowrap -ml-4" :to="{ name : 'webshopproducts' }">{{ $t('product') }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="dd-container">
                                    <span @click="showSupplierDropdown = !showSupplierDropdown" class="flex space-x-4 cursor-pointer align-items-center text-gray-900 font-semibold">
                                        {{ $t('supplierOrderManager') }}
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </span>
                                    <div class="sub-dropdown" v-if="showSupplierDropdown">
                                        <div>
                                            <ul>
                                                <li class="">
                                                    <router-link class="no-underline no-deco text-color-theme" :to="{ path : `${$route.params.id}/supplier-orders` }">{{ $t('supplierOrderOverview') }}</router-link>
                                                </li>
                                                <li class="">
                                                    <router-link class="no-underline no-deco text-color-theme" :to="{ name : 'supplier', params : { id : `${$route.params.id}` } }">{{ $t('supplier') }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="dd-container">
                                    <span @click="showWebshopDropdown = !showWebshopDropdown" class="flex justify-between space-x-4 align-items-center cursor-pointer text-gray-900 font-semibold">
                                        Select webshop
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <div class="sub-dropdown" v-if="showWebshopDropdown">
                                        <div>
                                            <ul v-for="provider in GET_WEBSHOP_PROVIDER" :key="provider.id">
                                                <li class="">
                                                    <router-link class="no-underline no-deco text-color-theme" :to="{ path : `${$route.params.id}/orders` }">{{ provider.name }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="container mt-3">
                                <div class="metric-section-title">
                                    <i class="mdi mdi-chart-box"></i>
                                    <div>{{ $t('metricsOverview') }}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-xl-4 mb-3">
                                        <div class="card metric-green highlight-tile">
                                            <div class="tile-label">{{ $t('totalMonth') }}</div>
                                            <div class="tile-value">
                                                <span class="" id="total-orders-today">{{ 0 }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-xl-4 mb-3">
                                        <div class="card metric-blue highlight-tile">
                                            <div class="tile-label">{{ $t('averageMargin') }}</div>
                                            <div class="tile-value">
                                                <span class="" id="profit-margin">{{ $services.helpers.animateValue('profit-margin', 0, 12, 2000) }}</span><span class="">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-xl-4 mb-3">
                                        <div class="card metric-yellow highlight-tile">
                                            <div class="tile-label">{{ $t('averagePerOrder') }}</div>
                                            <div class="tile-value">
                                                <span class="" id="average-profit-order">{{ $services.helpers.animateValue('average-profit-order', 0, 322, 4000) }}</span><span class="">:-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row mt-4">
                                    <div class="col-md-8 h-full space-y-4">
                                        <div class="revenue-row p-3">
                                            <div class="apex-chart-canvas">
                                            </div>
                                        </div>
                                        <div class="profit-row p-3">
                                            <div class="apex-chart-canvas">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 h-full items-row">
                                            <h3>{{ $t('mostPopularItems') }}</h3>
                                            <ul>
                                                <li>1. SKU-2323  Apple Phones</li>
                                                <li>2. SKU-2323  Apple Phones</li>
                                                <li>3. SKU-2323  Apple Phones</li>
                                                <li>4. SKU-2323  Apple Phones</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import debounce from 'lodash.debounce'
    // import Paginate from 'vuejs-paginate'

    export default {
        name : 'WebshopOverview',
        components : {
            // Notification : () => import('./../../customComponent/NotificationAlert'),
            // paginate : Paginate,
        },
        data () {
            return {
                loading : false,
                processing : false,
                showProductDropdown : false,
                showSupplierDropdown :  false,
                showWebshopDropdown : false,
            }
        },
        computed : {
            ...mapGetters({
                GET_WEBSHOP_PROVIDER : 'customer/GET_WEBSHOP_PROVIDER'
            })
        },
        mounted () {
            // this.getProvider()
        },
        methods : {
            hideDropdown () {
                this.showProductDropdown = false
                // this.showSupplierDropdown = false
                // this.showWebshopDropdown = false
            }, 
            getProvider () {
                this.$store.dispatch('customer/getIntegrationProvider')
            } 
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.icon-dashboard-style{
    width: 50px;
    border-radius: 24px;
    height: 50px;

    i{
        font-size: 2rem;
        // color: $custom-text-color;
    }
}
.icon-green-bg{
    // background-color: $cus-green;
}
.icon-yellow-bg{
    // background: $hover-yellow;
}
.icon-blue-bg{
    // background-color: $blue-color
}
.icon-blue{
    font-size: 1.5rem;
    // color: $blue-color
}
.override-pad-top-bottom{
    padding-top: unset !important;
    padding-bottom: unset !important;
}

.icon-width-div{
    width: 35% !important;
}
.icon-color-dashboard{
    color: #ffff !important;
}
.metric-green{
    background-color:#62c367;
}
.metric-blue{
    background-color: #024DB5;
}
.metric-yellow{
    background: rgba(255, 201, 23, 0.65);
}
.revenue-row, .profit-row{
    background: rgba(217, 217, 217, 0.2);
    border: 1px solid #024DB5;
    box-sizing: border-box;
    border-radius: 10px;
}
.items-row{
    background: #F3FBF8;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}
.items-row h3{
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}
.items-row ul{
    margin: 0;
    padding: 0;
}
.items-row ul li{
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #e2e2e2;
    font-size: 13px;
}

// .page-title-top{
//     font-family: 'Roboto', sans-serif;
//     font-weight: 500;
//     font-size: 2rem !important;
//     color: #000;
// }

// .page-title-subheading{
//     font-family: 'Roboto', sans-serif;
//     font-weight: 400;
//     font-size: 1.1rem !important;
//     color: #000;
// }

// .fab-btn-top {
//     .btn-open-options {
//         border-radius: 50px;
//         padding: 0;
//         height: 54px;
//         line-height: 54px;
//         width: 54px;
//         text-align: center;
//         display: block;
//         box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
//         // margin-top: -27px;
//     }
// } 
.icon-md{
    font-size: 2rem !important;
}
.align-box-center, .middle-section{
    width: 50%;
    margin: 0 auto;
}
// .tabs-animated .nav-link.active{
//     color: #FFC917 !important;
// }

// .tabs-animated .nav-link::before{
//     background: unset;
//     height: unset !important;
// }
// .tabs-animated .nav-link{
//     font-family: 'Roboto', sans-serif;
//     font-weight: 500;
//     color : $theme-color;
//     font-size: 1.5rem !important;
// }
.app-theme-gray .main-card > .card-body > .card-title::before{
    background: unset !important;
}
// .card-title{
//     font-family: 'Roboto', sans-serif;
//     font-weight: 700;
//     font-size: 2rem !important;
//     color : $theme-color;
// }
.center-icon-div-middle{
    margin: 0 auto;
    width: 50%;
}
.percen-color{
    color: #D02626;
}
.font-bold{
    font-weight: 700;
    font-size: 1.5rem;
}
.center-btn-view{
    margin: 0 auto;
    width: 30%;
}
.icon-circle-wt{
    width: 30px;
    height: 30px;
    background: #ffff;
    border-radius: 50%;
}
.mr-neg{
    margin-right: -2rem !important;
}
.tb-btn-pad{
    padding: 0.4rem 0.5rem !important;
}
.webshop-img{
    width: 80px;
    height: 80px;
}
.action-table-width{
    width: 15%;
}
.table-body{
    font-size: 1.5rem !important;
}
.webshop-icon{
    width: 100px;
    height: 80px;
}
.override-form-field{
    border: 0;
    // border-bottom: 2px solid $theme-color;
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.label-text{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #092C5C;
    font-size: 1.4rem !important;
}
.dd-container{
    position: relative;
    margin: 0 !important;
}
.dd-container > span{
    padding: 10px 20px;
    display: flex;
    border-left: 1px solid #eee;
}
.dropdown-margin-adjust{
    margin: -20px -20px 0px -20px;
    padding: 0;
    border-bottom: 1px solid #D9D9D9;
}
.dropdown-margin-adjust .sub-dropdown{
    position: absolute;
    left: 0;
    right: -1px;
    top: 100%;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 3px 2px #00000008;
    z-index: 12;
}
.dropdown-margin-adjust .sub-dropdown li{
    padding: 5px 20px;
    cursor: pointer;
}
.dropdown-margin-adjust .sub-dropdown li:hover{
    background-color: #f9f9f9;
}
.highlight-tile{
    border-radius: 10px;
    color: #fff;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    text-align: center;
}
.highlight-tile .tile-label{
    font-weight: 500;
    font-size: 20px;
}
.highlight-tile .tile-value{
    font-weight: 600;
    font-size: 40px;
}
.metric-section-title{
    display: flex;
    font-size: 24px;
    margin: 0px 0 20px;
    align-items: center;
    font-weight: 500;
}
.metric-section-title i{
    margin-right: 5px;
}
</style>